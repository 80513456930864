<template>
    <v-dialog v-model="dialog" persistent width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="isNew" rounded v-on="on" v-bind="attrs" color="green" depressed class="white--text">
          <v-icon>mdi-plus</v-icon>
          {{ $t("Add New School") }}
        </v-btn>
        <v-icon v-if="!isNew" @click="editSchool" v-on="on" v-bind="attrs" color="blue">mdi-pen</v-icon>
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <h1>{{ isNew ? $t("Add New School") : $t('edit') }}</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('name')" v-model="structure.name"></v-text-field>
            </v-col>
            <v-col md="6">
                <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('Dashboard Users Counts')" type="number"
                 v-model="structure.dashboard_users"></v-text-field>
            </v-col>
            <v-col md="6">
                <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('App Users Counts')" type="number"
                 v-model="structure.app_users"></v-text-field>
            </v-col>
            
            
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green" depressed class="white--text" @click="submit" :loading="btnLoading">{{ $t("storage")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="clearData">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        btnLoading: false,
        dialog: false,
        structure: {
            name: null,
            dashboard_users: null,
            app_users: null,
        },
      };
    },
    props: {
  
      isNew: {
        type: Boolean,
        default: true,
      },
      item: {
        type: Object,
        default: () => {
          return {};
        },
      }
    },
    computed: {
  
    },
    methods: {
  
  
  
      async submit() {
        try {
  
     
            await axios.put(`/school/${this.item.id}`, this.structure);
            this.$Notifications(
              this.$t('edit success'),
              { rtl: true, timeout: 2000 },
              "success"
            );
        
  
          this.$emit("Submitted");
           this.clearData();
        } catch (err) {
        } finally {
          this.btnLoading = false;
  
        }
      },
      clearData() {
        this.structure.name = null;
        this.structure.dashboard_users = null;
        this.structure.app_users = null;  
        this.dialog = false;
      },
      editSchool(){
        if (!this.isNew) {      
        this.structure.name = this.item.name;
        this.structure.dashboard_users = this.item.dashboard_users;
        this.structure.app_users = this.item.app_users;
        }
      }

    },
    created() {  
    },
  };
  </script>
  
  <style></style>