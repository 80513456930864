<template>
    <v-dialog v-model="dialog"  width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="isNew" @click="addNewSchool" rounded v-on="on" v-bind="attrs" color="green" depressed class="white--text">
          <v-icon>mdi-plus</v-icon>
          {{ $t("Add New School") }}
        </v-btn>
        <v-icon v-if="!isNew" v-on="on" v-bind="attrs" color="blue">mdi-pen</v-icon>
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <h1>{{ isNew ? $t("Add New School") : $t('edit') }}</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="4">
              <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('School Name')" v-model="structure.school.name"></v-text-field>
            </v-col>
            <v-col md="4">
                <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('Dashboard Users Counts')" type="number"
                 v-model="structure.school.dashboard_users"></v-text-field>
            </v-col>
            <v-col md="4">
                <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('App Users Counts')" type="number"
                 v-model="structure.school.app_users"></v-text-field>
            </v-col>
           <v-col md="6">
            <app-image-input-file :resetImage="resetImageInput" :createFeature="true"
                                                :imgHeight="140" :imgWidth="125" :placeHolder="$t('image')"
                                                @fileValue="setPhoto($event)"
                                                @fileRemoved="deletePhoto"></app-image-input-file>
           </v-col>
            <v-col md="6">
              <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('Admin Name')" 
                 v-model="structure.user.name"></v-text-field>
                 <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('username')" 
                 v-model="structure.user.username"></v-text-field>
                 <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('password')" 
                 v-model="structure.user.password"></v-text-field>
            </v-col>
            <v-col md="4">
              <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('hr.employee.email')" 
                 v-model="structure.user.email"></v-text-field>
            </v-col>
            <v-col md="4">
              <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t('phone number')" type="number"
                 v-model="structure.user.phone"></v-text-field>
            </v-col>
            <v-col md="4">
              <AutoCompleteField :label="$t('Role')" endPoint="/role/auto-complete" v-model="structure.user.role_id" outlined/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
         
          <v-btn color="green"  class="white--text" @click="submit()" :loading="btnLoading">{{ $t("storage")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="clearData">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from "axios";
  import imageInputFile from "../../../../components/base/imageInputFile.vue";
  import AutoCompleteField from "../../components/core/AutoCompleteField.vue";
  export default {
    components: {
      appImageInputFile: imageInputFile,
      AutoCompleteField,
    },
    data() {
      return {
        btnLoading: false,
        dialog: false,
        resetImageInput: false,
        structure: {
          school: {
            name: null,
            dashboard_users: null,
            app_users: null,
          },
          user: {
            name: null,
            username: null,
            password: null,
            role_id: null,
            image: null,
            email: null,
            phone: null,
          },
        },
      };
    },
    props: {
  
      isNew: {
        type: Boolean,
        default: true,
      },
      item: {
        type: Object,
        default: () => {
          return {};
        },
      }
    },
    computed: {
  
    },
    methods: {
  
  
  
      async submit() {
        

        try {
  
          this.btnLoading = true;
          let form = new FormData();
          for (let key in this.structure) {
            if (this.structure[key]) {
                if (key == 'school') {
                  
                    let school = this.structure[key]
                    form.append('school[name]',school['name']);
                    form.append('school[dashboard_users]',school['dashboard_users']);
                    form.append('school[app_users]',school['app_users']);
                }
             if (key == 'user') {
                    let user = this.structure[key]
                    form.append('user[name]',user['name']);
                    form.append('user[username]',user['username']);
                    form.append('user[password]',user['password']);
                    form.append('user[role_id]',user['role_id']);
                    form.append('user[image]',user['image']);
                    form.append('user[email]',user['email']);
                    form.append('user[phone]',user['phone']);
                } 
            }
          }
          await axios.post("/school", form);
            this.$Notifications(
              this.$t('add success'),
              { rtl: true, timeout: 2000 },
              "success"
            );
        //   if (this.isNew) {
        //     await axios.post("/school", form);
        //     this.$Notifications(
        //       this.$t('add success'),
        //       { rtl: true, timeout: 2000 },
        //       "success"
        //     );
        //   } else {
        //     await axios.put(`/school-variable/${this.item.id}`, this.structure);
        //     this.$Notifications(
        //       this.$t('edit success'),
        //       { rtl: true, timeout: 2000 },
        //       "success"
        //     );
        //   }
  
        this.$emit("Submitted");
           this.clearData();
        } catch (err) {
        } finally {
          this.btnLoading = false;
  
        }
      },
      setPhoto(file) {
        this.structure.user.image = file;
      },
      deletePhoto() {
        this.structure.user.image = null;
      },
      clearData() {
        this.structure.school.name = null;
        this.structure.school.dashboard_users = null;
        this.structure.school.app_users = null;
        this.structure.user.name = null;
        this.structure.user.username = null;
        this.structure.user.password = null;
        this.structure.user.role_id = null;
        this.structure.user.image = null;
        this.structure.user.email = null;
        this.structure.user.phone = null;
        this.resetImageInput = true; 
        this.dialog = false;
      },
      addNewSchool(){
        this.clearData();
      
        this.resetImageInput = false;

      },
    },
     created() {
       
      if (!this.isNew) {
  
  
        this.structure.name = this.item.name;
        this.structure.type = this.item.type;
        this.structure.status = this.item.status;
  
  
      }
  
    },
  };
  </script>
  
  <style></style>