<template>
    <div>
      <v-container class="py-10">
        <v-row>
          <v-col md="12" class="d-flex  pl-5">
            <appAddSchool @Submitted="fetchData(1)"/>
          </v-col>
          <v-col md="12">
            <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
              @re-fetch-paginated-data="fetchData($event)" :enableDelete="false">
              <template slot="editFeature" slot-scope="{ row }">
                <deleteItem path="school" :id="row.id" @record-deleted="fetchData(1)"/>
                <appEditSchool :is-new="false" @Submitted="fetchData(1)" :item="row"/>
              </template>
            </app-base-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  <script>
  import addSchool from "./addSchool.vue";
  import editSchool from "./editSchool.vue";
  import deleteItem from "../../components/core/delete.vue";
  import axios from "axios";
  export default {
    components: {
      appAddSchool: addSchool,
      appEditSchool: editSchool,
      deleteItem,
    },
    data() {
      return {
        tableOptions: {
          tableData: [],
          tableHeaders: ["id", "name", "Dashboard Users Counts", "App Users Counts", "settings"],
          accessTableData: [["id"], ["name"], ["dashboard_users"], ["app_users"]],
          loading: false,
          tableMeta: {
            total: 10,
            page: 1,
          },
        },
      };
    },
    methods: {
      async fetchData(page) {
        this.tableOptions.loading = true;
        let params = {
          size: 10,
          page: page,
        };
        const response = await axios.get("/school", { params });
        if (response.status == 200) {
          console.log(response.data.data);
          this.tableOptions.tableData = response.data.data,
          this.tableOptions.tableMeta.total = response.data.meta.total;
          this.tableOptions.tableMeta.page = response.data.meta.current_page;
        }
        this.tableOptions.loading = false;
      },
    },
    created() {
      this.fetchData(1);
    },
  };
  </script>
    
  <style></style>
    